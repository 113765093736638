/* DetailsPanel.css */

/* Panel Styling */
.details-panel {
    background-color: #fff;
    border-radius: 8px;
    padding: 4%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: auto;
  }
  
  /* Title Styling */
  .panel-title {
    font-size: clamp(16px, 2vw, 20px); /* Responsive font size */
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  /* Content Styling */
  .content {
    display: flex;
    flex-direction: column;
  }
  
  /* Individual Row Styling */
  .detail-row {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid #f0f0f0;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures no overflow */
  }
  
  /* Label Container Styling */
  .label-container {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 1 30%; /* Takes 40% of space */
  }
  
  /* Label Text Styling */
  .label {
    font-size: clamp(14px, 1.5vw, 16px); /* Responsive font size */
    color: #6c757d;
  }
  
  /* Value Container Styling */
  .value-container {
    flex: 1 1 65%; 
    text-align: right;
    word-break: break-word; /* Prevents overflow for long content */
  }
  
  /* Value Text Styling */
  .value {
    font-size: clamp(14px, 1.5vw, 16px); /* Responsive font size */
    font-weight: 'medium';
    color: #343a40;
  }
  
  /* Link Styling */
  .value-link {
    font-size: clamp(14px, 1.5vw, 16px);
    font-weight: 500;
    color: #007bff;
    text-decoration: none;
  }
  