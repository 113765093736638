.chartpanel {
    background-color: #fff;
    border-radius: 8px;
    padding: 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 2%;
}
.charttitle {
    font-size: clamp(16px, 2vw, 20px); /* Responsive font size */
    font-weight: bold;
    margin-bottom: 0%;
}

.chartpanelpie {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.piechart {
    margin: 5% 5% 0% 5%;
}
