.home {
    display: flex;
    flex-direction: row;
}

.home__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto; /* Makes main content scrollable */
    height: 100vh; /* Ensures content stretches full height */
}

.headinghome {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.headingtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.order-analytics {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    margin-bottom: 2%;
}

.order-charts {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}