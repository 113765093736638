@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Roboto');

.setuppage {
    display: flex;
    flex-direction: row;
}

.setupmain {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.heading {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.headingtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.supportbutton {
    margin-bottom: 8px;
}

.setupsection {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.sectiondetails{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 2%;
}

.sectiondetailsheading {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: x-large;
}

.sectiondetailsdesc {
    font-style: italic;
    margin-bottom: 0%;
}

.sectionblock {
    width: 50%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    padding: 2%;
}

.amazondetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto 1%;
}

.sellerinfo {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    align-content: center;
    text-align: left;
    font-size: medium;
    margin-bottom: 0;
}

.button {
    all: unset;
    background-color: #d98231;
    border-radius: 4px;
    box-sizing: border-box;
    border-color: #171a1f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10%;
    padding: 2% 5%;
    cursor: pointer;
}

button:disabled,
button[disabled]{
  background-color: #7ac083;
  cursor: not-allowed;
}
  
.text-wrapper-2 {
    color: white;
    font-family: "Manrope-Regular", Helvetica;
    font-size: large;
    white-space: nowrap;
}

.text-wrapper-3 {
    color: #5E6161;
    font-family: "Manrope-Regular", Helvetica;
    font-size: large;
    white-space: nowrap;
}

.logo-amazon {
    height: 16px;
    width: 16px;

}

.syncform {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.syncbutton {
    margin-top: 2%;
}

.refreshtext{
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
}