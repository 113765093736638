.sidebar {
  width: 20%;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2%;
  box-sizing: border-box;
}

.sidebar__logo {
  margin-bottom: 10%;
}

.sidebar__section h4 {
  font-size: medium;
  font-weight: 600;
  color: #6b7280;
  text-transform: uppercase;
  margin-top: 10%;
}

.sidebar__item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  color: #4b5563;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar__item:hover {
  color: #4f46e5;
}

.sidebar__item svg {
  margin-right: 10px;
}

.sidebar__item--active {
  color: #fff;
  background-color: #4f46e5;
  border-radius: 8px;
  padding: 10px 10px;
}

.sidebar__item--active:hover {
  color: #fff;
}

.notification-dot {
  background-color: #e02424;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 8px;
}

.sidebar__logout {
  margin: 20px 0;
}

.sidebar__profile {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.sidebar__profile img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sidebar__profile div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__profile strong {
  font-size: 14px;
  color: #111827;
}

.sidebar__profile span {
  font-size: 12px;
  color: #6b7280;
}

.sidebar__item {
  /* Styles for main items */
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar__subitems {
  padding-left: 20px;
  padding-bottom: 5px;
  /* Indent sub-items */
}

.sidebar__subitem {
  /* Styles for sub-items */
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  color:#37474f
}

.sidebar__subitem:hover {
  color: #4f46e5;
}

.sidebar__subitem--active {
  color: #fff;
  background-color: #4f46e5;
  border-radius: 8px;
  padding: 10px 10px;
}

.sidebar__subitem--active:hover {
  color: #fff;
}
.sidebar__subitem---active {
  color: #fff;
  background-color: #4f46e5;
  border-radius: 8px;
  padding: 10px 10px;
}