.sku-main {
    display: flex;
    flex-direction: row;
}

.skudetails {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
}

.panel_and_stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid #ccc;
}

.panel {
    font-family: Arial, Helvetica, sans-serif;
    width: 40%;
    margin-right: 2%;
    height: auto;
    margin-bottom: 2%;
}

.stats {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.statComp {
    background-color: aliceblue;
}

.cardheading {
    margin-bottom: 0;
}

.statGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3%;
    justify-content: center;
    margin-left: 2%;
    margin-bottom: 3%;
}

.skuchart {
    margin-top: 2%;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid #ccc;
}

.chartpanel {
    background-color: #fff;
    border-radius: 8px;
    padding: 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 2%;
}
.charttitle {
    font-size: clamp(16px, 2vw, 20px); /* Responsive font size */
    font-weight: bold;
    margin-bottom: 0%;
}