.charges {
    display: flex;
    flex-direction: row;
}

.charges__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    /* Makes main content scrollable */
    height: 100vh;
    /* Ensures content stretches full height */
}

.charges__main p {
    margin-top: auto;
}

.heading {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.headingtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.tablecomponent {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 100%;
    width: auto;
}