.orders {
    display: flex;
    flex-direction: row;
}

.orders__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    /* Makes main content scrollable */
    height: 100vh;
    /* Ensures content stretches full height */
}

.orders__main p {
    margin-top: auto;
}

.heading {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.headingtitle {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.supportbutton {
    margin-bottom: 8px;
}

.tablecomponent {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 100%;
    width: auto;
}

.orderdetails {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
}

.secondpanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: 1px solid rgb(204, 204, 204);

}

.secondstats {
    width: 50%;
    margin-bottom: 2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #f0f0f0;
    background-color: #fff;
    border-radius: 5%;
    margin-top: 2%;
    overflow-x: auto;

}

.financial-details-box {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    margin: 2% 0% 2% 2%;
    border-radius: 5%;
    width: 50%;
}

.title {
    font-size: clamp(16px, 2vw, 20px);
    font-weight: bold;
    margin-bottom: 5%;
    color: rgba(37, 43, 54, 0.95);
}