.loginpage {
    display: flex;
    height: 100vh;
}

.loginpanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.logowrapper {
    text-align: center;
    margin-bottom: 1rem;
}

.login-container {
    width: 100%;
    max-width: 300px;
}

.login-form-button {
    width: 100%;
}

.error-message {
    color: #ff4d4f;
    margin-bottom: 1rem;
}

.toggle-signup {
    margin-top: 1%;
}