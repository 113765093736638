body {
  font-family: 'Poppins', sans-serif;
}

.left-container {
    width: 30%;
    height: 100vh; /* Full viewport height */
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .content-wrapper {
    text-align: center;
    margin-top: 15%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .login-artwork-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem !important;
    color: #6C31ED !important;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    line-height: 1.3 !important;
    max-width: 80%;
    margin: 0 auto !important;
  }
  
  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .illustration {
    max-width: 100%; /* Adjust the size to fit the container */
  }

  @media (max-width: 768px) {
    .login-artwork-title {
      font-size: 2rem !important;
    }
  }
  
