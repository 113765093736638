.cost-and-metrics {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
}

.cogspiepanel {
    background-color: #fff;
    border-radius: 8px;
    padding: 2%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    height: auto;
}

.metrics-panel {
    margin-left: 2%;
    width: 70%;
    height: auto;
}